* {
  font-family: "Oxanium", sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Anton&family=Oxanium:wght@200..800&display=swap');

@media screen and (max-width: 499px) {

  .light2 {
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    z-index: 5;
  }

  .logoPic {
    width: 220px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .icons {
    display: flex;
  }

  .socialIcon {
    padding-right: 10px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  .socialIcon img {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
   /* margin-top: 32vh;*/
   margin-top: 16vh;
  }

  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
  }

  .videoBg {
    position: absolute;
    top: 10%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-10%);
    transform: translateX(-50%) translateY(-10%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    overflow: hidden;
    filter: brightness(70%);
    /* Adjust the brightness as needed */
  }

  .content {
    text-align: center;
    z-index: 5;
  }

  .title {
    color: #ffffff;
    font-size: 45px;
    background: -webkit-linear-gradient(#ffffff, #7949b3ce);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .title:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: #9f78bd;
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 #9f78bd,
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 #9f78bd,
        .5em 0 0 #694693;
    }
  }

  .subT {
    color: #d4d4d493;
    text-shadow: 0 0 10px #adadad9f;
    font-size: 15px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .light2 {
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    z-index: 5;
  }

  .logoPic {
    width: 300px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .icons {
    display: flex;
  }

  .socialIcon {
    padding-right: 15px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  .socialIcon img {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 19vh;
  }

  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
  }

  .videoBg {
    position: absolute;
    top: 10%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-10%);
    transform: translateX(-50%) translateY(-10%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    overflow: hidden;
    filter: brightness(70%);
    /* Adjust the brightness as needed */
  }

  .content {
    text-align: center;
    z-index: 5;
  }

  .title {
    color: #ffffff;
    font-size: 55px;
    background: -webkit-linear-gradient(#ffffff, #7949b3ce);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .title:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: #9f78bd;
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 #9f78bd,
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 #9f78bd,
        .5em 0 0 #694693;
    }
  }

  .subT {
    color: #d4d4d493;
    text-shadow: 0 0 10px #adadad9f;
    font-size: 15px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .light2 {
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    z-index: 5;
  }

  .logoPic {
    width: 380px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .icons {
    display: flex;
  }

  .socialIcon {
    padding-right: 15px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  .socialIcon img {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 19vh;
  }

  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
  }

  .videoBg {
    position: absolute;
    top: 10%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-10%);
    transform: translateX(-50%) translateY(-10%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    overflow: hidden;
    filter: brightness(70%);
    /* Adjust the brightness as needed */
  }

  .content {
    text-align: center;
    z-index: 5;
  }

  .title {
    color: #ffffff;
    font-size: 75px;
    background: -webkit-linear-gradient(#ffffff, #7949b3ce);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .title:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: #9f78bd;
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 #9f78bd,
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 #9f78bd,
        .5em 0 0 #694693;
    }
  }

  .subT {
    color: #d4d4d493;
    text-shadow: 0 0 10px #adadad9f;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  .light2 {
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    z-index: 5;
  }

  .logoPic {
    width: 400px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .icons {
    display: flex;
  }

  .socialIcon {
    padding-right: 15px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  .socialIcon img {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 19vh;
  }

  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
  }

  .videoBg {
    position: absolute;
    top: 10%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-10%);
    transform: translateX(-50%) translateY(-10%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    overflow: hidden;
    filter: brightness(70%);
    /* Adjust the brightness as needed */
  }

  .content {
    text-align: center;
    z-index: 5;
  }

  .title {
    color: #ffffff;
    font-size: 80px;
    background: -webkit-linear-gradient(#ffffff, #7949b3ce);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .title:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: #9f78bd;
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 #9f78bd,
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 #9f78bd,
        .5em 0 0 #694693;
    }
  }

  .subT {
    color: #d4d4d493;
    text-shadow: 0 0 10px #adadad9f;

  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .light2 {
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    z-index: 5;
  }

  .logoPic {
    width: 43%;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .icons {
    display: flex;
  }

  .socialIcon {
    padding-right: 15px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  .socialIcon img {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
   /* margin-top: 25vh;*/
    margin-top: 19vh;
  }

  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
  }

  .videoBg {
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    max-width: 100%;
    min-height: 100%;
    filter: brightness(70%);
    /* Adjust the brightness as needed */

  }

  .content {
    text-align: center;
    z-index: 5;
  }

  .title {
    color: #ffffff;
    font-size: 80px;
    background: -webkit-linear-gradient(#ffffff, #7949b3ce);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .title:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: #9f78bd;
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 #9f78bd,
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 #9f78bd,
        .5em 0 0 #694693;
    }
  }

  .subT {
    color: #d4d4d493;
    text-shadow: 0 0 10px #adadad9f;
    text-align: center;
  }
}

@media screen and (min-width: 1920px) {

  .light2 {
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 25px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    z-index: 5;
  }

  .logoPic {
    width: 60%;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .icons {
    display: flex;
  }

  .socialIcon {
    padding-right: 23px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  .socialIcon img {
    width: 45px;
    height: 45px;
    cursor: pointer;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    /*margin-top: 26vh;*/
    margin-top: 21vh;
  }

  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
  }

  .videoBg {
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    max-width: 100%;
    min-height: 100%;
    filter: brightness(70%);
    /* Adjust the brightness as needed */
  }

  .content {
    text-align: center;
    z-index: 5;
  }

  .title {
    color: #ffffff;
    font-size: 120px;
    background: -webkit-linear-gradient(#ffffff, #7949b3ce);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .title:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: #9f78bd;
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 #9f78bd,
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 #9f78bd,
        .5em 0 0 #694693;
    }
  }

  .subT {
    color: #d4d4d493;
    font-size: 24px;
    text-shadow: 0 0 10px #adadad9f;
    width: 1500px;
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {

  .light2 {
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    z-index: 5;
  }

  .logoPic {
    width: 75%;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .icons {
    display: flex;
  }

  .socialIcon {
    padding-right: 28px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  .socialIcon img {
    width: 62px;
    height: 62px;
    cursor: pointer;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    /*margin-top: 26vh;*/
    margin-top: 21vh;
    z-index: 10;
  }

  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
  }

  .videoBg {
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    max-width: 100%;
    min-height: 100%;
    filter: brightness(70%);
    /* Adjust the brightness as needed */

  }

  .content {
    text-align: center;
    z-index: 5;
  }

  .title {
    color: #ffffff;
    font-size: 160px;
    background: -webkit-linear-gradient(#ffffff, #7949b3ce);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .title:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: #9f78bd;
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 #9f78bd,
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 #9f78bd,
        .5em 0 0 #694693;
    }
  }

  .subT {
    color: #d4d4d493;
    font-size: 33px;
    text-shadow: 0 0 10px #adadad9f;
    width: 2000px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 3840px) {

  .light2 {
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    z-index: 5;
  }

  .logoPic {
    width: 125%;
  }


  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .icons {
    display: flex;
  }

  .socialIcon {
    padding-right: 45px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon:hover {
    transform: scale(1.1);
  }

  .socialIcon img {
    width: 90px;
    height: 90px;
    cursor: pointer;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    /*margin-top: 30vh;*/
    margin-top: 22vh;
    width: 100%;
  }

  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
  }

  .videoBg {
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    max-width: 100%;
    min-height: 100%;
    filter: brightness(70%);
    /* Adjust the brightness as needed */

  }

  .content {
    text-align: center;
    z-index: 5;
  }

  .title {
    color: #ffffff;
    font-size: 200px;
    background: -webkit-linear-gradient(#ffffff, #7949b3ce);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .title:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: #9f78bd;
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 #9f78bd,
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 #9f78bd,
        .5em 0 0 #694693;
    }
  }

  .subT {
    color: #d4d4d493;
    font-size: 45px;
    text-shadow: 0 0 10px #adadad9f;
    width: 2600px;
  }
}